<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :customClass="'job-create'"
    v-if="getPermission('job:create')"
  >
    <template v-slot:header-title>
      <h1 class="form-title">
        <v-layout>
          <v-flex class="flex-grow-0"> Visit For </v-flex>
          <v-flex
            class="m-0 form-title-create-link pl-2"
            link
            :class="{ 'text-truncate': !lodash.isEmpty(customer) }"
          >
            <template v-if="lodash.isEmpty(customer)">Client Name</template>
            <template v-else>{{ customer.display_name }}</template>
            <v-icon
              link
              large
              color="cyan"
              class="mx-2"
              v-if="lodash.isEmpty(customer)"
              >mdi-plus-circle-outline</v-icon
            >
          </v-flex>
        </v-layout>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading || isPageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        depressed
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || isPageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        v-on:click="updateOrCreate"
      >
        Save Job
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="jobForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate"
      >
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 70vh; position: relative"
        >
          <div class="p-5 pt-0">
            <v-row>
              <v-col cols="7" class="pb-0">
                <div>
                  <label class="font-weight-700 font-size-16">Job Title</label>
                  <v-text-field
                    v-model.trim="jobCreate.job_title"
                    dense
                    filled
                    label="Title"
                    solo
                    flat
                    :disabled="isPageLoading"
                    color="cyan"
                    :rules="[
                      validateRules.required(jobCreate.job_title, 'Job Title'),
                      validateRules.minLength(
                        jobCreate.job_title,
                        'Job Title',
                        1
                      ),
                      validateRules.maxLength(
                        jobCreate.job_title,
                        'Job Title',
                        100
                      ),
                    ]"
                  ></v-text-field>
                  <v-textarea
                    v-model.trim="jobCreate.description"
                    auto-grow
                    dense
                    filled
                    :disabled="isPageLoading"
                    color="cyan"
                    label="Description"
                    solo
                    flat
                    :rules="[
                      validateRules.minLength(
                        jobCreate.description,
                        'Description',
                        1
                      ),
                      validateRules.maxLength(
                        jobCreate.description,
                        'Description',
                        1024
                      ),
                    ]"
                    row-height="25"
                  ></v-textarea>
                </div>

                <v-layout class="my-4">
                  <v-flex md6 class="mr-2 custom-border-right">
                    <table width="100%">
                      <tr>
                        <td class="font-weight-600">
                          Service Location
                          <v-icon
                            class="ml-3"
                            v-on:click="customerPropertyDialog = true"
                            color="cyan"
                            small
                            >mdi-pencil</v-icon
                          >
                          <v-icon
                            class="ml-3"
                            v-on:click="routeToServiceHistory()"
                            color="cyan"
                            small
                            >mdi-history</v-icon
                          >
                        </td>
                      </tr>
                      <tr>
                        <td class="py-0">
                          {{ property.property_address }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-600">
                          Contact details
                          <v-chip
                            label
                            x-small
                            color="green"
                            outlined
                            class="ml-3"
                            v-if="property.is_tenant"
                            >Tenant</v-chip
                          >
                          <v-icon
                            v-if="false"
                            class="ml-3"
                            v-on:click="
                              person_type = 'property';
                              customerPersonDialog = true;
                            "
                            color="cyan"
                            small
                            >mdi-pencil</v-icon
                          >
                        </td>
                      </tr>
                      <tr v-if="property_contact_person.display_name">
                        <td class="py-0">
                          {{ property_contact_person.display_name }}
                        </td>
                      </tr>
                      <tr v-if="property_contact_person.primary_phone">
                        <td class="py-0">
                          {{ property_contact_person.primary_phone }}
                        </td>
                      </tr>
                      <tr v-if="property_contact_person.primary_email">
                        <td class="py-0">
                          <v-layout>
                            <v-flex>{{
                              property_contact_person.primary_email
                            }}</v-flex>
                            <v-flex>
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-switch
                                    class="m-0 p-0"
                                    color="cyan"
                                    dense
                                    v-model="jobCreate.property_person_notify"
                                    inset
                                  ></v-switch>
                                </template>
                                <span
                                  v-if="jobCreate.property_person_notify == 1"
                                  >Send Notification</span
                                >
                                <span v-else>No Notification</span>
                              </v-tooltip>
                            </v-flex>
                          </v-layout>
                        </td>
                      </tr>
                    </table>
                  </v-flex>
                  <v-flex md6 class="ml-2">
                    <table width="100%">
                      <tr>
                        <td class="font-weight-600">
                          Billing Address
                          <v-icon
                            class="ml-3"
                            v-on:click="customerBillingDialog = true"
                            color="cyan"
                            small
                            >mdi-pencil</v-icon
                          >
                        </td>
                      </tr>
                      <tr>
                        <td class="py-0">
                          {{ billing.property_address }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-600">
                          Contact details
                          <v-icon
                            class="ml-3"
                            v-on:click="
                              person_type = 'billing';
                              customerPersonDialog = true;
                            "
                            color="cyan"
                            small
                            >mdi-pencil</v-icon
                          >
                        </td>
                      </tr>
                      <tr v-if="billing_contact_person.display_name">
                        <td class="py-0">
                          {{ billing_contact_person.display_name }}
                        </td>
                      </tr>
                      <tr v-if="billing_contact_person.primary_phone">
                        <td class="py-0">
                          {{ billing_contact_person.primary_phone }}
                        </td>
                      </tr>
                      <tr v-if="billing_contact_person.primary_email">
                        <td class="py-0">
                          <v-layout>
                            <v-flex>{{
                              billing_contact_person.primary_email
                            }}</v-flex>
                            <v-flex>
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-switch
                                    class="m-0 p-0"
                                    color="cyan"
                                    dense
                                    v-model="jobCreate.billing_person_notify"
                                    inset
                                  ></v-switch>
                                </template>
                                <span
                                  v-if="jobCreate.billing_person_notify == 1"
                                  >Send Notification</span
                                >
                                <span v-else>No Notification</span>
                              </v-tooltip>
                            </v-flex>
                          </v-layout>
                        </td>
                      </tr>
                    </table>
                  </v-flex>
                </v-layout>

                <v-layout v-if="false" class="my-4">
                  <v-flex md4>
                    <table width="100%">
                      <tr>
                        <td class="font-weight-600">
                          Service Location
                          <v-icon
                            class="ml-3"
                            v-on:click="customerPropertyDialog = true"
                            color="cyan"
                            small
                            >mdi-pencil</v-icon
                          >
                        </td>
                      </tr>
                      <tr>
                        <td class="py-0">{{ property.property_address }}</td>
                      </tr>
                    </table>
                  </v-flex>
                  <v-flex md4 class="mx-2">
                    <table width="100%">
                      <tr>
                        <td class="font-weight-600">
                          Billing Address
                          <v-icon
                            class="ml-3"
                            v-on:click="customerBillingDialog = true"
                            color="cyan"
                            small
                            >mdi-pencil</v-icon
                          >
                        </td>
                      </tr>
                      <tr>
                        <td class="py-0">{{ billing.property_address }}</td>
                      </tr>
                    </table>
                  </v-flex>
                  <v-flex md4>
                    <table width="100%">
                      <tr>
                        <td class="font-weight-600">
                          Contact details
                          <v-icon
                            class="ml-3"
                            v-on:click="customerPersonDialog = true"
                            color="cyan"
                            small
                            >mdi-pencil</v-icon
                          >
                        </td>
                      </tr>
                      <tr>
                        <td class="py-0">{{ jobCreate.attention }}</td>
                      </tr>
                      <tr>
                        <td class="py-0">{{ contact_person.primary_phone }}</td>
                      </tr>
                      <tr>
                        <td class="py-0">{{ contact_person.primary_email }}</td>
                      </tr>
                    </table>
                  </v-flex>
                </v-layout>
              </v-col>
              <v-col cols="5" class="pb-0">
                <table width="100%">
                  <tr>
                    <td width="150" class="font-weight-600">Visit number</td>
                    <td class="font-weight-700">{{ jobCreate.barcode }}</td>
                  </tr>
                  <tr>
                    <td width="150" class="font-weight-600">
                      <label for="reference-no">Reference #</label>
                    </td>
                    <td>
                      <v-text-field
                        id="reference-no"
                        v-model.trim="jobCreate.reference"
                        dense
                        filled
                        :disabled="isPageLoading"
                        color="cyan"
                        label="Reference #"
                        :rules="[
                          validateRules.minLength(
                            jobCreate.reference,
                            'Reference',
                            1
                          ),
                          validateRules.maxLength(
                            jobCreate.reference,
                            'Reference',
                            100
                          ),
                        ]"
                        solo
                        flat
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td width="150" class="font-weight-600">
                      <label for="po-number-no">PO Number</label>
                    </td>
                    <td>
                      <v-text-field
                        id="po-number-no"
                        v-model.trim="jobCreate.po_number"
                        dense
                        filled
                        :disabled="isPageLoading"
                        color="cyan"
                        label="PO Number"
                        :rules="[
                          validateRules.minLength(
                            jobCreate.po_number,
                            'PO Number',
                            1
                          ),
                          validateRules.maxLength(
                            jobCreate.po_number,
                            'PO Number',
                            100
                          ),
                        ]"
                        solo
                        flat
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td width="150" class="font-weight-600">
                      <label for="job-type" class="required">Job Type</label>
                    </td>
                    <td>
                      <v-autocomplete
                        id="job-type"
                        v-model.trim="jobCreate.jobType"
                        :items="jobTypeList"
                        dense
                        filled
                        :disabled="isPageLoading"
                        color="cyan"
                        item-color="cyan"
                        item-text="value"
                        item-value="id"
                        label="Job Type"
                        solo
                        flat
                        multiple
                        :rules="[
                          validateRules.required(jobCreate.jobType, 'Job Type'),
                        ]"
                        return-object
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Job Type Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </td>
                  </tr>
                  <tr>
                    <td width="150" class="font-weight-600">
                      <label for="priority">Priority</label>
                    </td>
                    <td>
                      <v-autocomplete
                        id="priority"
                        v-model.trim="jobCreate.priority"
                        :items="jobPriorityList"
                        dense
                        filled
                        :disabled="isPageLoading"
                        color="cyan"
                        item-color="cyan"
                        item-text="value"
                        item-value="id"
                        label="Priority"
                        solo
                        flat
                        :rules="[
                          validateRules.required(
                            jobCreate.priority,
                            'Priority'
                          ),
                        ]"
                        return-object
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Priority Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </td>
                  </tr>
                  <tr v-if="false">
                    <td width="150" class="font-weight-600">
                      <label for="schedule-type">Schedule Type</label>
                    </td>
                    <td>
                      <v-autocomplete
                        id="schedule-type"
                        v-model.trim="jobCreate.scheduleTab"
                        :items="jobScheduleTypeList"
                        dense
                        filled
                        :disabled="isPageLoading"
                        color="cyan"
                        item-color="cyan"
                        item-text="value"
                        item-value="id"
                        label="Schedule Type"
                        :rules="[
                          validateRules.required(
                            jobCreate.scheduleTab,
                            'Schedule Type'
                          ),
                        ]"
                        solo
                        flat
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Schedule Type Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </td>
                  </tr>
                </table>
              </v-col>
              <v-col cols="12" class="pt-0 job-schedule">
                <v-container fluid>
                  <v-tabs
                    v-model="jobCreate.scheduleTab"
                    background-color="transparent"
                    centered
                    :disabled="isPageLoading"
                    color="cyan"
                    icons-and-text
                  >
                    <v-tab href="#one-off">
                      <div class="custom-tab-title d-flex">
                        <div class="custom-tab-icon pr-2">
                          <v-icon left large color="gray">mdi-calendar</v-icon>
                        </div>
                        <div class="text-left">
                          <h3 class="text-capitalize">One-Off Job</h3>
                          <p class="m-0 text-capitalize">
                            A one time job with one or more visits
                          </p>
                        </div>
                      </div>
                    </v-tab>
                    <v-tab href="#recurring">
                      <div class="custom-tab-title d-flex">
                        <div class="custom-tab-icon pr-2">
                          <v-icon left large color="gray"
                            >mdi-calendar-multiple</v-icon
                          >
                        </div>
                        <div class="text-left">
                          <h3 class="text-capitalize">Recurring Job</h3>
                          <p class="m-0 text-capitalize">
                            A contract job with repeating visits
                          </p>
                        </div>
                      </div>
                    </v-tab>
                    <v-tabs-items v-model="jobCreate.scheduleTab">
                      <v-tab-item :value="'one-off'">
                        <v-row>
                          <v-col :md="showCalendar ? 4 : 12">
                            <OneOffSchedule
                              :showCalendar="showCalendar"
                              :pageLoading="isPageLoading"
                            ></OneOffSchedule>
                          </v-col>
                          <v-col v-if="showCalendar" md="8" dense>
                            <Calendar
                              :showCalendar="showCalendar"
                              :pageLoading="isPageLoading"
                            ></Calendar>
                          </v-col>
                        </v-row>
                      </v-tab-item>
                      <v-tab-item :value="'recurring'">
                        <v-row>
                          <v-col :md="showCalendar ? 4 : 12">
                            <RecurringSchedule
                              :showCalendar="showCalendar"
                              :pageLoading="isPageLoading"
                            ></RecurringSchedule>
                          </v-col>
                          <v-col v-if="showCalendar" md="8" dense>
                            <Calendar
                              :showCalendar="showCalendar"
                              :pageLoading="isPageLoading"
                            ></Calendar>
                          </v-col>
                        </v-row>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-tabs>
                </v-container>
              </v-col>
              <v-col cols="12" v-if="getPermission('line-item:create')">
                <v-container fluid>
                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title class="headline grey lighten-4">
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        Line Items
                      </h3>
                    </v-card-title>
                    <v-card-text ref="lineItem" class="p-6 font-size-16">
                      <v-row dense>
                        <v-col cols="12" class="pt-0 pb-0">
                          <JobLineItemNew
                            is-job
                            can-update
                            :tax-value="taxValue"
                            v-on:update:line-item="updateLineItem($event)"
                            v-on:update:line-item-calculation="
                              updateLineItemCalculation($event)
                            "
                          ></JobLineItemNew>
                          <!-- <JobLineItem
                            isTicket
                            :updateData="duplicateLineItems"
                            :pageLoading="isPageLoading"
                          ></JobLineItem> -->
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-container>
              </v-col>
              <v-col v-if="false" cols="12">
                <JobInvoiceSchedule
                  :pageLoading="isPageLoading"
                ></JobInvoiceSchedule>
              </v-col>
              <v-col cols="12">
                <JobTermsConditions
                  :updateData="duplicateJob"
                  :pageLoading="isPageLoading"
                ></JobTermsConditions>
              </v-col>
              <v-col cols="12">
                <JobNotesAttachment
                  :updateData="duplicateJob"
                  :pageLoading="isPageLoading"
                  :isTicket="isTicket"
                ></JobNotesAttachment>
              </v-col>
            </v-row>
          </div>
        </perfect-scrollbar>
      </v-form>
      <template v-if="warrantyLineItemDialog">
        <Dialog :commonDialog="warrantyLineItemDialog">
          <template v-slot:title>
            <template v-if="warrantyLineItemFormData.product_type == 'goods'"
              >Product</template
            >
            <template
              v-else-if="warrantyLineItemFormData.product_type == 'equipment'"
              >Equipment</template
            >
            #{{ warrantyLineItemFormData.product_barcode }} -
            {{ warrantyLineItemFormData.product_name }}
          </template>
          <template v-slot:body>
            <v-container>
              <v-form
                ref="warrantyItemDialogForm"
                v-model.trim="warrantyItemDialogValid"
                lazy-validation
                v-on:submit.stop.prevent="addWarrantyItem"
              >
                <v-row>
                  <v-col md="12">
                    <label>Warranty Serial No.</label>
                    <v-text-field
                      v-model.trim="warrantyLineItemFormData.warranty_unique_id"
                      dense
                      filled
                      color="cyan"
                      label="Serial No."
                      :rules="[
                        validateRules.required(
                          warrantyLineItemFormData.warranty_unique_id,
                          'Serial No.'
                        ),
                        validateRules.minLength(
                          warrantyLineItemFormData.warranty_unique_id,
                          'Serial No.',
                          1
                        ),
                        validateRules.maxLength(
                          warrantyLineItemFormData.warranty_unique_id,
                          'Serial No.',
                          100
                        ),
                      ]"
                      solo
                      flat
                    ></v-text-field>
                  </v-col>
                  <v-col md="12">
                    <label>Warranty Remarks</label>
                    <v-textarea
                      v-model.trim="
                        warrantyLineItemFormData.warranty_description
                      "
                      dense
                      filled
                      color="cyan"
                      label="Remarks"
                      solo
                      flat
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="!warrantyItemDialogValid"
              class="mx-2 custom-grey-border custom-bold-button text-white"
              color="cyan"
              v-on:click="addWarrantyItem"
            >
              Add
            </v-btn>
            <v-btn
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="warrantyLineItemDialog = false"
            >
              Cancel
            </v-btn>
          </template>
        </Dialog>
      </template>
      <template v-if="customerPersonDialog">
        <CustomerPersonDialog
          disabled-auto-select
          :customerPersonDialog="customerPersonDialog"
          :customer="customer.id"
          v-on:close="closeDialog"
          v-on:selectCustomerPerson="selectCustomerPerson"
        ></CustomerPersonDialog>
      </template>
      <template v-if="customerPropertyDialog">
        <CustomerPropertyDialog
          disabled-auto-select
          :customerPropertyDialog="customerPropertyDialog"
          :customer="customer.id"
          v-on:close="closeDialog"
          v-on:selectCustomerProperty="selectCustomerProperty"
        ></CustomerPropertyDialog>
      </template>
      <template v-if="customerBillingDialog">
        <CustomerBillingDialog
          disabled-auto-select
          :customerBillingDialog="customerBillingDialog"
          :customer="customer.id"
          v-on:close="closeDialog"
          v-on:selectCustomerBilling="selectCustomerBilling"
        ></CustomerBillingDialog>
      </template>
    </template>
    <template v-slot:barcode-setting>
      <BarcodeSetting
        endpoint="job"
        :barcodeDialog="barcodeDialog"
        :dialogWidth="600"
        :barcodeSetting="barcodeSetting"
        v-on:close:barcode-dialog="barcodeDialog = false"
      ></BarcodeSetting>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
// import JobLineItem from "@/view/pages/partials/Line-Item.vue";
import JobLineItemNew from "@/view/pages/partials/Line-Item-New.vue";
import JobTermsConditions from "@/view/pages/partials/Terms-Conditions.vue";
import JobInvoiceSchedule from "@/view/pages/partials/Invoice-Schedule.vue";
import JobNotesAttachment from "@/view/pages/partials/Notes-Attachment.vue";
import BarcodeSetting from "@/view/pages/partials/Barcode-Setting.vue";
import Calendar from "@/view/pages/partials/Calendar.vue";
import OneOffSchedule from "@/view/pages/job/partials/One-Off-Schedule.vue";
import RecurringSchedule from "@/view/pages/job/partials/Recurring-Schedule.vue";
import { QUERY } from "@/core/services/store/request.module";
import JobMixin from "@/core/lib/job/job.mixin";
import Dialog from "@/view/pages/partials/Dialog";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import CustomerBillingDialog from "@/view/pages/partials/Select-Customer-Billing.vue";

export default {
  mixins: [CommonMixin, JobMixin, ValidationMixin],
  name: "job-create",
  title: "Create Job",
  data() {
    return {
      time: null,
      forcePush: false,
      timePicker: null,
      customerLoading: true,
      taxValue: 9,
      lineItemType: "new",
      lineItemWarranty: new Array(),
      lineItemExpiredWarranty: new Array(),
      isTicket: true,
      jobCustomer: 0,
      jobContactPerson: 0,
      jobCustomerProperty: 0,
      job: 0,
      jobArr: {},
      changeAttention: false,
      barcodeDialog: false,
      pageLoading: true,
      projectList: [],
      contractList: [],
      quotationList: [],
      lineItem: [],
      lineItemCalculation: {},
      termsCondition: null,
      noteAttachment: {},
      customer: {},
      property: {},
      billing: {},
      property_contact_person: {},
      billing_contact_person: {},
      contact_person: {},
      barcodeSetting: {},
      customerPersonDialog: false,
      customerBillingDialog: false,
      customerPropertyDialog: false,
      customerPerson: 0,
      customerProperty: 0,
      customerBilling: 0,
      person_type: null,
    };
  },
  components: {
    Dialog,
    JobLineItemNew,
    JobTermsConditions,
    JobNotesAttachment,
    JobInvoiceSchedule,
    BarcodeSetting,
    Calendar,
    OneOffSchedule,
    RecurringSchedule,
    CreateUpdateTemplate,
    CustomerPersonDialog,
    CustomerBillingDialog,
    CustomerPropertyDialog,
  },
  watch: {
    lineItemType(param) {
      if (param == "maintenance") {
        if (this.lodash.isEmpty(this.lineItemWarranty)) {
          this.getWarrantyByCustomer();
        }
      }
      if (param == "contract") {
        if (this.lodash.isEmpty(this.lineItemExpiredWarranty)) {
          this.getExpiredWarrantyByCustomer();
        }
      }
    },
  },
  methods: {
    routeToServiceHistory() {
      this.forcePush = true;
      this.$nextTick(() => {
        this.$router.push(
          this.getDefaultRoute("property.detail", {
            params: { id: this.property.id },
            query: {
              tab: "property-history",
            },
          })
        );
      });
    },
    closeDialog() {
      this.customerPersonDialog = false;
      this.customerBillingDialog = false;
      this.customerPropertyDialog = false;
    },
    selectCustomerPerson(param) {
      if (this.person_type == "property") {
        if (this.jobCreate.property_contact_person == param) {
          this.closeDialog();
          return false;
        }
        this.jobCreate.property_contact_person = param;
      }
      if (this.person_type == "billing") {
        if (this.jobCreate.billing_contact_person == param) {
          this.closeDialog();
          return false;
        }
        this.jobCreate.billing_contact_person = param;
      }
      this.closeDialog();
      this.pushToRouteJob();
    },
    selectCustomerProperty(param) {
      if (this.jobCreate.property == param) {
        this.closeDialog();
        return false;
      }
      this.jobCreate.property = param;
      this.closeDialog();
      this.pushToRouteJob();
    },
    selectCustomerBilling(param) {
      if (this.jobCreate.billing == param) {
        this.closeDialog();
        return false;
      }
      this.jobCreate.billing = param;
      this.closeDialog();
      this.pushToRouteJob();
    },
    pushToRouteJob() {
      this.$router
        .replace(
          this.getDefaultRoute("job.create", {
            query: {
              customer: this.jobCreate.customer ?? undefined,
              property_contact_person:
                this.jobCreate.property_contact_person ?? undefined,
              billing_contact_person:
                this.jobCreate.billing_contact_person ?? undefined,
              property: this.jobCreate.property ?? undefined,
              billing: this.jobCreate.billing ?? undefined,
            },
          })
        )
        .then(() => {
          this.getAttributes([
            "customer",
            "property_contact_person",
            "billing_contact_person",
            "property",
            "billing",
          ]);
        });
    },
    getWarrantyByCustomer() {
      const _this = this;
      _this.customerLoading = true;
      let filter = {
        /*search: _this.filter.search || undefined,*/
        customer: _this.lodash.toSafeInteger(_this.jobCustomer) || undefined,
      };
      _this.$store
        .dispatch(QUERY, {
          url: "warranty",
          data: { filter, status: "active" },
        })
        .then(({ data }) => {
          _this.lineItemWarranty = data.rows;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.customerLoading = false;
        });
    },
    getExpiredWarrantyByCustomer() {
      const _this = this;
      _this.customerLoading = true;
      let filter = {
        /*search: _this.filter.search || undefined,*/
        customer: _this.lodash.toSafeInteger(_this.jobCustomer) || undefined,
      };
      _this.$store
        .dispatch(QUERY, {
          url: "warranty",
          data: { filter, status: "expired" },
        })
        .then(({ data }) => {
          _this.lineItemExpiredWarranty = data.rows;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.customerLoading = false;
        });
    },
    getAttributes(attributes) {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "job/options",
          data: {
            attribute: attributes || null,
            customer: _this.jobCreate.customer || null,
            property_contact_person:
              _this.jobCreate.property_contact_person || null,
            billing_contact_person:
              _this.jobCreate.billing_contact_person || null,
            property: _this.jobCreate.property || null,
            billing: _this.jobCreate.billing || null,
            "schedule-type": _this.jobCreate.scheduleTab == "one-off" ? 1 : 2,
          },
        })
        .then(({ data }) => {
          if (_this.lodash.isEmpty(data.customer) === false) {
            _this.customer = data.customer;
            _this.jobCreate.customer = data.customer.id;
          }

          if (_this.lodash.isEmpty(data.property) === false) {
            _this.property = data.property;
            _this.jobCreate.property = data.property.id;
            _this.jobCustomerProperty = _this.lodash.toSafeInteger(
              data.property.id
            );
          }

          if (_this.lodash.isEmpty(data.billing) === false) {
            _this.billing = data.billing;
            _this.jobCreate.billing = data.billing.id;
            _this.jobCustomerBilling = _this.lodash.toSafeInteger(
              data.billing.id
            );
          }

          if (_this.lodash.isEmpty(data.property_contact_person) === false) {
            _this.jobCreate.property_contact_person =
              data.property_contact_person.id;
            _this.property_contact_person = data.property_contact_person;
          }

          if (_this.lodash.isEmpty(data.billing_contact_person) === false) {
            _this.jobCreate.billing_contact_person =
              data.billing_contact_person.id;
            _this.billing_contact_person = data.billing_contact_person;
            _this.jobCreate.attention =
              data.billing_contact_person.display_name;
          }

          if (_this.lodash.isEmpty(data.barcode_setting) === false) {
            _this.barcodeSetting = data.barcode_setting;

            _this.duplicateJob = {
              term_conditions: _this.barcodeSetting.terms_conditions,
              admin_remark: _this.barcodeSetting.admin_note,
              client_remark: _this.barcodeSetting.client_note,
            };
          }

          if (data.barcode) {
            _this.jobCreate.barcode = data.barcode;
          }

          if (_this.lodash.isEmpty(data.ticket_types) === false) {
            _this.jobTypeList = data.ticket_types;
          }
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    async loadDuplicateData(jobId) {
      const _this = this;
      try {
        let job = await _this.getDuplicateJob(jobId);
        let lineItems = await _this.getDuplicateJobLineItems(jobId);
        _this.updateDuplicateAttributes({ job, lineItems: lineItems.rows });
      } catch (error) {
        _this.logError(error);
      }
    },
    async loadQuotationData(quotationId) {
      const _this = this;
      try {
        let quotation = await _this.getQuotation(quotationId);
        let lineItems = await _this.getQuotationLineItems(quotationId);
        _this.updateQuotationAttributes({ quotation, lineItems: lineItems });
      } catch (error) {
        _this.logError(error);
      }
    },
  },
  beforeMount() {
    const _this = this;
    _this.jobCustomer = _this.lodash.toSafeInteger(_this.$route.query.customer);

    _this.jobCreate.customer = _this.lodash.toSafeInteger(
      _this.$route.query.customer
    );
    _this.jobCreate.property_contact_person = _this.lodash.toSafeInteger(
      _this.$route.query.property_contact_person
    );
    _this.jobCreate.billing_contact_person = _this.lodash.toSafeInteger(
      _this.$route.query.billing_contact_person
    );
    _this.jobCreate.property = _this.lodash.toSafeInteger(
      _this.$route.query.property
    );
    _this.jobCreate.billing = _this.lodash.toSafeInteger(
      _this.$route.query.billing
    );

    _this.jobCustomerProperty = _this.lodash.toSafeInteger(
      _this.$route.query.property
    );

    if (!_this.jobCustomer || !_this.jobCustomerProperty) {
      _this.$router.go(-1);
    }

    let duplicateJobId = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );
    if (duplicateJobId > 0) {
      _this.loadDuplicateData(duplicateJobId);
    }
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Visit", route: "visit" },
      { title: "Create" },
    ]);

    _this.getAttributes([
      "customer",
      "property_contact_person",
      "billing_contact_person",
      "property",
      "billing",
      "barcode",
      "barcode_setting",
      "ticket_types",
    ]);
    _this.getTicketTypes();
  },
  computed: {
    isPageLoading() {
      return this.pageLoading || this.formLoading;
    },
    CityName() {
      return this.lodash.isEmpty(this.property.city) === false
        ? this.property.city.name
        : null;
    },
    StateName() {
      return this.lodash.isEmpty(this.property.state) === false
        ? this.property.state.name
        : null;
    },
    CountryName() {
      return this.lodash.isEmpty(this.property.country) === false
        ? this.property.country.name
        : null;
    },
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.forcePush) {
      next();
    } else if (_this.JobCreated && _this.LineItemCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
