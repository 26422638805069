import { JobEventBus } from "@/core/lib/job/job.lib";
import { TermConditionEventBus } from "@/core/lib/term.condition.lib";
import { NoteAttachmentEventBus } from "@/core/lib/note.attachment.lib";
import { InvoiceScheduleEventBus } from "@/core/lib/invoice/invoice.schedule.lib";
import LineItemMixin from "@/core/lib/line-item/line.item.mixin";
import {
  POST,
  PUT,
  GET,
  QUERY,
  CLEAR_ERROR,
} from "@/core/services/store/request.module";
import { JobScheduleTeamEventBus } from "@/core/lib/job/job.schedule.team.lib";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [LineItemMixin],
  data() {
    return {
      showCalendar: false,
      jobTypeList: new Array(),
      jobPriorityList: [
        {
          id: 1,
          value: "High",
        },
        {
          id: 2,
          value: "Medium",
        },
        {
          id: 3,
          value: "Low",
        },
      ],
      jobScheduleTypeList: [
        {
          id: "one-off",
          value: "One-Off Job",
        },
        {
          id: "recurring",
          value: "Recurring Job",
        },
      ],
      visitCreated: {},
      OneOffSchedule: {},
      assignedEngineers: {},
      termCondition: null,
      momentObject: moment,
      lineItem: null,
      notesAttachment: null,
      invoiceSchedule: null,
      lineItemCalculation: null,
      customer: 0,
      customerPerson: 0,
      customerProperty: 0,
      customerDialog: false,
      customerPersonDialog: false,
      customerPropertyDialog: false,
      ScheduleCreated: false,
      ScheduleEngineerCreated: false,
      RecurringScheduleCreated: false,
      ScheduleRecurringEngineerCreated: false,
      LineItemCreated: false,
      JobCreated: false,
      JobData: {},
      RecurringPattern: {},
      RecurringSchedule: {},
      RecurringDates: [],
      duplicateLineItems: [],
      duplicateJob: {},
      warrantyLineItemFormData: {},
      warrantyLineItemDialog: false,
      warrantyItemDialogValid: true,
      jobCreate: {
        jobType: new Array(),
        scheduleTab: "one-off",
        priority: 2,
        scheduleLater: 0,
        quote: null,
        scheduleType: 1,
        property_contact_person: 0,
        billing_contact_person: 0,
        property_person_notify: 1,
        billing_person_notify: 0,
        project: null,
        contract: null,
        reference: null,
        po_number: null,
        barcode: null,
        job_title: null,
        attention: null,
        description: null,
      },
      responseSchedule: {
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
      },
    };
  },
  watch: {
    /*"jobCreate.jobType"(param) {
      try {
        if (param.length > 0) {
          this.jobCreate.job_title = param.map(x => x.value).join(", ");
          JobScheduleTeamEventBus.$emit(
            "update:skills",
            param.map(x => x.value)
          );
        } else {
          this.jobCreate.job_title = null;
        }
      } catch (error) {
        this.logError(error);
      }
    },*/
    "jobCreate.scheduleTab"(param) {
      InvoiceScheduleEventBus.$emit("update:schedule-tab", param);
      this.getAttributes(["barcode", "barcode_setting"]);
    },
  },
  methods: {
    updateLineItem(rows) {
      this.lineItem = rows;
    },
    updateLineItemCalculation(row) {
      this.lineItemCalculation.taxApplied = row.apply_tax;
      this.lineItemCalculation.discountValue = row.discount_value;
      this.lineItemCalculation.adjustmentAmount = row.adjustment;
      this.lineItemCalculation.discountType = 1;
      this.lineItemCalculation.discountValueType = row.discount_value_type;
    },
    addWarrantyItemConfirm(row) {
      this.warrantyLineItemFormData = this.lodash.cloneDeep(row);
      this.warrantyLineItemDialog = true;
    },
    addWarrantyItem() {
      //
      this.warrantyLineItemDialog = false;
    },
    getProductType(type) {
      let result = null;
      switch (type) {
        case "goods":
          result = "Product";
          break;
        case "service":
          result = "Service";
          break;
        case "equipment":
          result = "Equipment";
          break;
      }
      return result;
    },
    selectCustomerDialog() {
      this.customerDialog = true;
    },
    selectCustomerPersonDialog() {
      this.customerPersonDialog = true;
    },
    selectCustomerPropertyDialog() {
      this.customerPropertyDialog = true;
    },
    getTicketTypes() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, {
              url: "job/types",
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    CreateJobSchedule({ job, schedule, team }) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (
            _this.lodash.isEmpty(schedule) === false &&
            moment(schedule.start_date).isValid()
          ) {
            _this.$store
              .dispatch(PUT, {
                url: "job/" + job + "/visit",
                data: {
                  start_date: schedule.start_date
                    ? moment(schedule.start_date).format("YYYY-MM-DD")
                    : moment().format("YYYY-MM-DD"),
                  start_time: schedule.start_time
                    ? moment(schedule.start_time, ["h:mm A"]).format("HH:mm")
                    : moment().startOf("day").format("HH:mm"),
                  end_date: schedule.end_date
                    ? moment(schedule.end_date).format("YYYY-MM-DD")
                    : moment(schedule.start_date).format("YYYY-MM-DD"),
                  end_time: schedule.end_time
                    ? moment(schedule.end_time, ["h:mm A"]).format("HH:mm")
                    : moment().endOf("day").format("HH:mm"),
                  email: team.email_team,
                },
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject("Invalid Start Date in Schedule.");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    AssignTeam({ job, visit, team }) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (
            _this.lodash.isEmpty(team.assigned_team) === false &&
            _this.lodash.isArray(team.assigned_team)
          ) {
            _this.$store
              .dispatch(PUT, {
                url: "job/" + job + "/visit/" + visit + "/engineer",
                data: {
                  engineer: team.assigned_team,
                },
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            resolve(true);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    AssignRecurringTeam({ job, team }) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (
            _this.lodash.isEmpty(team.assigned_team) === false &&
            _this.lodash.isArray(team.assigned_team)
          ) {
            _this.$store
              .dispatch(PUT, {
                url: "job/" + job + "/allocate",
                data: { engineer: team.assigned_team },
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            resolve(true);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getQuotation(quotation) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (quotation) {
            _this.$store
              .dispatch(GET, {
                url: "quotation/" + quotation,
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject("Quotation is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getQuotationLineItems(quotation) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (quotation) {
            _this.$store
              .dispatch(QUERY, {
                url: "line-item",
                data: {
                  quotation: quotation,
                },
              })
              .then(({ data }) => {
                resolve(data.rows);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject("Quotation is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getDuplicateJob(job) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (job) {
            _this.$store
              .dispatch(GET, {
                url: "job/" + job,
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject("Job is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getDuplicateJobLineItems(job) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (job) {
            _this.$store
              .dispatch(GET, {
                url: "job/" + job + "/line-items",
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject("Job is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    updateDuplicateAttributes({ job, lineItems }) {
      const _this = this;
      _this.duplicateLineItems = [];
      if (
        _this.lodash.isEmpty(lineItems) === false &&
        _this.lodash.isArray(lineItems)
      ) {
        for (let i = 0; i < lineItems.length; i++) {
          lineItems[i].id = undefined;
          lineItems[i].warranty_data = _this.lodash.isArray(
            lineItems[i].warranty_data
          )
            ? lineItems[i].warranty_data.map((row) => {
                return {
                  days: row.days,
                  field: row.field,
                  value: row.value,
                  warranty: row.warranty,
                };
              })
            : [];
          _this.duplicateLineItems.push(lineItems[i]);
        }
      }
      if (_this.lodash.isEmpty(job) === false) {
        job.ticket.id = undefined;
        _this.duplicateJob = job.ticket;
        _this.jobCreate.jobType = [];
        for (let i = 0; i < _this.jobTypeList.length; i++) {
          if (
            _this.lodash.includes(
              job.ticket.ticket_type,
              _this.jobTypeList[i].value
            )
          ) {
            _this.jobCreate.jobType.push(_this.jobTypeList[i]);
          }
        }
        _this.jobCreate.scheduleTab =
          job.ticket.type == 1 ? "one-off" : "recurring";
        _this.jobCreate.priority = job.ticket.priority;
        _this.jobCreate.scheduleType = job.ticket.type;
        _this.jobCreate.reference = job.ticket.reference;
        _this.jobCreate.attention = job.ticket.attention;
        _this.jobCreate.description = job.ticket.description;
      }
    },
    updateQuotationAttributes({ quotation, lineItems }) {
      const _this = this;
      _this.duplicateLineItems = [];
      if (
        _this.lodash.isEmpty(lineItems) === false &&
        _this.lodash.isArray(lineItems)
      ) {
        for (let i = 0; i < lineItems.length; i++) {
          lineItems[i].id = undefined;
          lineItems[i].warranty_data = _this.lodash.isArray(
            lineItems[i].warranty_data
          )
            ? lineItems[i].warranty_data.map((row) => {
                return {
                  days: row.days,
                  field: row.field,
                  value: row.value,
                  warranty: row.warranty,
                };
              })
            : [];
          _this.duplicateLineItems.push(lineItems[i]);
        }
      }

      if (_this.lodash.isEmpty(quotation) === false) {
        _this.duplicateJob = {
          reference: quotation.reference,
          attention: quotation.attention,
          customer:
            _this.lodash.isEmpty(quotation.customer) === false
              ? quotation.customer.customer
              : 0,
          property:
            _this.lodash.isEmpty(quotation.property) === false
              ? quotation.property.property
              : 0,
          contact_person:
            _this.lodash.isEmpty(quotation.contact_person) === false
              ? quotation.contact_person.contact_person
              : 0,
          title: quotation.job_title,
          admin_remark: quotation.admin_remark,
          client_remark: quotation.client_remark,
          term_conditions: quotation.term_conditions,
          additional_remarks: quotation.additional_remarks,
          discount_value: quotation.discount_value,
          adjustment: quotation.adjustment,
          discount_type: quotation.discount_type,
          discount_value_type: quotation.discount_value_type,
          notify_admin: quotation.notify_admin,
          notify_customer: quotation.notify_customer,
        };
        _this.jobCreate.reference = _this.duplicateJob.reference;
        _this.jobCreate.attention = _this.duplicateJob.attention;
        _this.jobCreate.description = quotation.job_title;
        _this.jobCreate.quote = quotation.id;
      }
    },
    CreateRecurringSchedule({ job, pattern, schedule, dates }) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          const recurrenceData = pattern[pattern.type];
          let formData = new Object();
          switch (pattern.type) {
            case "daily": {
              formData = {
                day_count: _this.lodash.toSafeInteger(recurrenceData.day_count),
                occurrence: _this.lodash.toSafeInteger(schedule.occurrence),
                start_date: _this.responseSchedule.start_date,
                end_date: _this.responseSchedule.end_date,
                start_time: _this.responseSchedule.start_time,
                end_time: _this.responseSchedule.end_time,
                recurring_pattern: _this.lodash.toSafeInteger(
                  recurrenceData.recurring_pattern
                ),
                end_mode: _this.lodash.toSafeInteger(schedule.end_mode),
                dates,
              };
              break;
            }
            case "weekly": {
              formData = {
                weeks: _this.lodash.isArray(recurrenceData.weeks)
                  ? recurrenceData.weeks.join(",")
                  : null,
                week_count: _this.lodash.toSafeInteger(
                  recurrenceData.week_count
                ),
                occurrence: _this.lodash.toSafeInteger(schedule.occurrence),
                start_date: _this.responseSchedule.start_date,
                end_date: _this.responseSchedule.end_date,
                start_time: _this.responseSchedule.start_time,
                end_time: _this.responseSchedule.end_time,
                end_mode: _this.lodash.toSafeInteger(schedule.end_mode),
                dates,
              };
              break;
            }
            case "monthly": {
              formData = {
                week_day: _this.lodash.toSafeInteger(recurrenceData.week_day),
                day_count: _this.lodash.toSafeInteger(recurrenceData.day_count),
                week_count: _this.lodash.toSafeInteger(
                  recurrenceData.week_count
                ),
                month_count:
                  _this.lodash.toSafeInteger(
                    recurrenceData.recurring_pattern
                  ) === 1
                    ? _this.lodash.toSafeInteger(recurrenceData.day_month_count)
                    : _this.lodash.toSafeInteger(recurrenceData.month_count),
                recurring_pattern: _this.lodash.toSafeInteger(
                  recurrenceData.recurring_pattern
                ),
                occurrence: _this.lodash.toSafeInteger(schedule.occurrence),
                start_date: _this.responseSchedule.start_date,
                end_date: _this.responseSchedule.end_date,
                start_time: _this.responseSchedule.start_time,
                end_time: _this.responseSchedule.end_time,
                end_mode: _this.lodash.toSafeInteger(schedule.end_mode),
                dates,
              };
              break;
            }
          }

          _this.$store
            .dispatch(PUT, {
              url: "job/" + job + "/" + pattern.type + "-recurrence",
              data: formData,
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    async updateOrCreate() {
      const _this = this;

      let validateLineItem = _this.lodash.compact(
        _this.lodash.map(_this.lineItem, function (row) {
          return row.product_id;
        })
      );

      if (_this.lodash.isEmpty(validateLineItem)) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Select product/service then try again.")
        );
        return false;
      }

      if (!_this.$refs.jobForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      const formData = {
        po_number: _this.jobCreate.po_number,
        reference: _this.jobCreate.reference,
        customer: _this.jobCreate.customer || null,
        property_contact_person:
          _this.jobCreate.property_contact_person || null,
        billing_contact_person: _this.jobCreate.billing_contact_person || null,
        property_person_notify: _this.jobCreate.property_person_notify || null,
        billing_person_notify: _this.jobCreate.billing_person_notify || null,
        property: _this.jobCreate.property || null,
        billing: _this.jobCreate.billing || null,
        title: _this.jobCreate.job_title,
        tax_value: _this.taxValue,
        attention: _this.jobCreate.attention,
        schedule_later: _this.jobCreate.scheduleLater,
        description: _this.jobCreate.description,
        priority: _this.lodash.isObject(_this.jobCreate.priority)
          ? _this.lodash.toSafeInteger(_this.jobCreate.priority.id)
          : _this.lodash.toSafeInteger(_this.jobCreate.priority),
        job_type: _this.jobCreate.jobType,
        type: _this.jobCreate.scheduleTab == "one-off" ? 1 : 2,
        term_conditions: _this.termCondition,
        additional_remarks:
          _this.lodash.isEmpty(_this.lineItemCalculation) === false
            ? _this.lineItemCalculation.description
            : null,
        tax_applied: _this.lineItemCalculation.taxApplied,
        discount_value: _this.lineItemCalculation.discountValue,
        adjustment: _this.lineItemCalculation.adjustmentAmount,
        discount_type: _this.lineItemCalculation.discountType,
        discount_value_type: _this.lineItemCalculation.discountValueType,
        admin_remark:
          _this.lodash.isEmpty(_this.notesAttachment) === false
            ? _this.notesAttachment.admin_notes
            : null,
        client_remark:
          _this.lodash.isEmpty(_this.notesAttachment) === false
            ? _this.notesAttachment.client_notes
            : null,
        documents:
          _this.lodash.isEmpty(_this.notesAttachment) === false
            ? _this.notesAttachment.documents
            : [],
        notify_admin:
          _this.lodash.isEmpty(_this.notesAttachment) === false
            ? _this.notesAttachment.notify_admin
            : 0,
        notify_customer:
          _this.lodash.isEmpty(_this.notesAttachment) === false
            ? _this.notesAttachment.notify_customer
            : 0,
        notify_engineer:
          _this.lodash.isEmpty(_this.notesAttachment) === false
            ? _this.notesAttachment.notify_engineer
            : 0,
        notify_for_invoice:
          _this.lodash.isEmpty(_this.invoiceSchedule) === false
            ? _this.lodash.toSafeInteger(_this.invoiceSchedule.remind_me) ||
              null
            : null,
        invoice_type:
          _this.lodash.isEmpty(_this.invoiceSchedule) === false
            ? _this.lodash.toSafeInteger(_this.invoiceSchedule.type) || null
            : null,
        invoice_duration:
          _this.lodash.isEmpty(_this.invoiceSchedule) === false
            ? _this.lodash.toSafeInteger(_this.invoiceSchedule.duration) || null
            : null,
      };

      if (!_this.JobCreated) {
        try {
          _this.JobData = await _this.$store.dispatch(POST, {
            url: "job",
            data: formData,
          });
          _this.job = _this.JobData.data.id;
          _this.JobCreated = true;
        } catch (error) {
          _this.JobCreated = false;
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Job is not created. Please try again.")
          );
        }
      }

      if (!_this.LineItemCreated && _this.JobCreated) {
        try {
          await _this.CreateLineItems({
            type: "ticket",
            parent: _this.job,
            formData: _this.lineItem,
          });
          _this.LineItemCreated = true;
        } catch (error) {
          _this.LineItemCreated = false;
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Line items are not created. Please try again.")
          );
        }
      }

      if (!formData.schedule_later) {
        if (formData.type == 2) {
          if (
            _this.RecurringDates.length &&
            !_this.RecurringScheduleCreated &&
            _this.JobCreated
          ) {
            try {
              await _this.CreateRecurringSchedule({
                job: _this.job,
                pattern: _this.RecurringPattern,
                dates: _this.RecurringDates,
                schedule: _this.RecurringSchedule,
              });
              _this.RecurringScheduleCreated = true;
            } catch (error) {
              _this.RecurringScheduleCreated = false;
              ErrorEventBus.$emit(
                "update:error",
                InitializeError(
                  "Recurring Schedule is not created. Please try again."
                )
              );
              return false;
            }
          }

          if (
            _this.isEngineerChannel() &&
            !_this.ScheduleRecurringEngineerCreated &&
            _this.JobCreated
          ) {
            try {
              let currentUser = _this.getCurrentUser();
              let currentUserId = _this.lodash.toSafeInteger(
                currentUser.engineer
              );
              if (currentUserId > 0) {
                await _this.AssignRecurringTeam({
                  job: _this.job,
                  team: { assigned_team: [currentUserId] },
                });
                _this.ScheduleRecurringEngineerCreated = true;
              }
            } catch (error) {
              _this.ScheduleRecurringEngineerCreated = false;
              ErrorEventBus.$emit(
                "update:error",
                InitializeError(
                  "Engineer are not allocated to Schedule. Please try again."
                )
              );
            }
          }

          if (
            _this.lodash.isEmpty(_this.assignedEngineers) === false &&
            !_this.ScheduleRecurringEngineerCreated &&
            _this.JobCreated
          ) {
            try {
              await _this.AssignRecurringTeam({
                job: _this.job,
                team: _this.assignedEngineers,
              });
              _this.ScheduleRecurringEngineerCreated = true;
            } catch (error) {
              _this.ScheduleRecurringEngineerCreated = false;
              ErrorEventBus.$emit(
                "update:error",
                InitializeError(
                  "Engineer are not allocated to Schedule. Please try again."
                )
              );
            }
          }
        } else {
          if (!_this.ScheduleCreated && _this.JobCreated) {
            try {
              _this.visitCreated = await _this.CreateJobSchedule({
                job: _this.job,
                schedule: _this.OneOffSchedule,
                team: _this.assignedEngineers,
              });
              _this.ScheduleCreated = true;
            } catch (error) {
              _this.ScheduleCreated = false;
              ErrorEventBus.$emit(
                "update:error",
                InitializeError("Schedule is not created. Please try again.")
              );
              return false;
            }
          }

          if (
            _this.isEngineerChannel() &&
            !_this.ScheduleEngineerCreated &&
            _this.JobCreated &&
            _this.ScheduleCreated
          ) {
            try {
              let currentUser = _this.getCurrentUser();
              let currentUserId = _this.lodash.toSafeInteger(
                currentUser.engineer
              );
              if (currentUserId > 0) {
                await _this.AssignTeam({
                  job: _this.job,
                  visit: _this.visitCreated.id,
                  team: { assigned_team: [currentUserId] },
                });
                _this.ScheduleEngineerCreated = true;
              }
            } catch (error) {
              _this.ScheduleEngineerCreated = false;
              ErrorEventBus.$emit(
                "update:error",
                InitializeError(
                  "Engineer are not allocated to Schedule. Please try again."
                )
              );
            }
          }

          if (
            _this.lodash.isEmpty(_this.assignedEngineers) === false &&
            !_this.ScheduleEngineerCreated &&
            _this.JobCreated &&
            _this.ScheduleCreated
          ) {
            try {
              await _this.AssignTeam({
                job: _this.job,
                visit: _this.visitCreated.id,
                team: _this.assignedEngineers,
              });
              _this.ScheduleEngineerCreated = true;
            } catch (error) {
              _this.ScheduleEngineerCreated = false;
              ErrorEventBus.$emit(
                "update:error",
                InitializeError(
                  "Engineer are not allocated to Schedule. Please try again."
                )
              );
            }
          }
        }
      }

      _this.formLoading = false;

      if (_this.JobCreated && _this.LineItemCreated) {
        _this.$router.push(
          _this.getDefaultRoute("visit", {
            query: {
              status: "all",
            },
          })
        );
      }
    },
  },
  beforeDestroy() {
    /*JobEventBus.$off("update:one-off-schedule");
    JobEventBus.$off("update:recurring-pattern");*/
    JobEventBus.$off("update:recurring-schedule");
    JobEventBus.$off("update:recurring-dates");
    /*JobScheduleTeamEventBus.$off("update:assigned-team");
    JobScheduleTeamEventBus.$off("update:schedule-later");    
    TermConditionEventBus.$off("update:term-condition");
    InvoiceScheduleEventBus.$off("update:invoice-schedule");
    NoteAttachmentEventBus.$off("update:notes-attachment");
    JobEventBus.$off("update:show-calendar");
    JobScheduleTeamEventBus.$off("get:schedule-team");*/
  },
  mounted() {
    const _this = this;

    JobScheduleTeamEventBus.$on("update:assigned-team", (argument) => {
      _this.assignedEngineers = argument;
    });

    JobScheduleTeamEventBus.$on("update:schedule-later", (argument) => {
      _this.jobCreate.scheduleLater = +argument;
    });

    JobEventBus.$on("update:one-off-schedule", (argument) => {
      _this.OneOffSchedule = argument;
    });

    JobEventBus.$on("update:recurring-pattern", (argument) => {
      _this.RecurringPattern = argument;
    });

    JobEventBus.$on("update:recurring-schedule", (argument) => {
      _this.RecurringType = _this.RecurringPattern.type;
      _this.RecurringSchedule = argument;
    });

    JobEventBus.$on("update:recurring-dates", (argument) => {
      _this.RecurringDates = argument;
    });

    TermConditionEventBus.$on("update:term-condition", (argument) => {
      _this.termCondition = argument;
    });

    InvoiceScheduleEventBus.$on("update:invoice-schedule", (argument) => {
      _this.invoiceSchedule = argument;
    });

    NoteAttachmentEventBus.$on("update:notes-attachment", (argument) => {
      _this.notesAttachment = argument;
    });

    JobEventBus.$on("update:show-calendar", () => {
      _this.showCalendar = !_this.showCalendar;
    });

    JobScheduleTeamEventBus.$on("get:schedule-team", (param) => {
      try {
        _this.responseSchedule = {
          start_date: param.start_date
            ? moment(param.start_date).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
          end_date: param.end_date
            ? moment(param.end_date).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
          start_time: param.start_time
            ? moment(param.start_time, ["h:mm A"]).format("HH:mm")
            : moment().startOf("day").format("HH:mm"),
          end_time: param.end_time
            ? moment(param.end_time, ["h:mm A"]).format("HH:mm")
            : moment().endOf("day").format("HH:mm"),
        };
      } catch (error) {
        _this.logError(error);
      }
    });
  },
};
