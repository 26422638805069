<template>
  <v-container fluid>
    <v-card
      flat
      class="custom-grey-border remove-border-radius"
      :disabled="pageLoading"
    >
      <v-card-title class="headline grey lighten-4">
        <h3 class="font-weight-700 custom-headline color-custom-blue">
          Terms &amp; Conditions
        </h3>
      </v-card-title>
      <v-card-text class="p-6 font-size-16">
        <editor v-model="termsConditions" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { TermConditionEventBus } from "@/core/lib/term.condition.lib";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";

export default {
  name: "terms-conditions",
  data() {
    return {
      timeoutLimit: 500,
      timeout: null,
      termsConditions: null,
    };
  },
  components: {
    editor: TinyMCE,
  },
  props: {
    pageLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    updateData: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
  },
  watch: {
    termsConditions() {
      const _this = this;
      clearTimeout(_this.timeout);
      _this.timeout = setTimeout(function () {
        TermConditionEventBus.$emit(
          "update:term-condition",
          _this.termsConditions
        );
      }, _this.timeoutLimit);
    },
    updateData: {
      deep: true,
      handler() {
        this.termsConditions = this.updateData.term_conditions;
      },
    },
  },
};
</script>
